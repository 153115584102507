/*
 *
 * StoreInfoPage reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  deliveryNotes: [],
};

function storeInfoPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.FETCH_DELIVERY_NOTE_SUCCESS:
      return {
        ...state,
        deliveryNotes: payload,
      };
    case ActionTypes.DELETE_DELIVERY_MESSAGE_SUCCESS: {
      const deliveryNotes = [...state.deliveryNotes];
      const deliveryNoteIndex = deliveryNotes.findIndex((p: any) => p.id === payload.id);
      if (deliveryNoteIndex > -1) {
        deliveryNotes.splice(deliveryNoteIndex, 1);
      }
      return {
        ...state,
        deliveryNotes: deliveryNotes,
      };
    }

    case ActionTypes.UPDATE_DELIVERY_MESSAGE_SUCCESS: {
      const deliveryNotes = state.deliveryNotes;

      payload.forEach((p: any) => {
        const deliveryNoteIndex = deliveryNotes.findIndex((p: any) => p.id === p.id);
        if (deliveryNoteIndex > -1) {
          deliveryNotes[deliveryNoteIndex] = p;
        }
      });

      return {
        ...state,
        deliveryNotes: deliveryNotes,
      };
    }
    default:
      return state;
  }
}

export default storeInfoPageReducer;
