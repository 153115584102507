/*
 *
 * StoreInfoPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const deliveryNotes = createAsyncAction(
  ActionTypes.FETCH_DELIVERY_NOTE_REQUEST,
  ActionTypes.FETCH_DELIVERY_NOTE_SUCCESS,
  ActionTypes.FETCH_DELIVERY_NOTE_FAILURE,
)<void, {}, Error>();
export const deleteDeliveryNoteSubmit = (data: any) => action(ActionTypes.DELETE_DELIVERY_MESSAGE, data);
export const deleteDeliveryNote = createAsyncAction(
  ActionTypes.DELETE_DELIVERY_MESSAGE_REQUEST,
  ActionTypes.DELETE_DELIVERY_MESSAGE_SUCCESS,
  ActionTypes.DELETE_DELIVERY_MESSAGE_FAILURE,
)<void, {}, Error>();
export const updateDeliveryNoteSubmit = (data: any) => action(ActionTypes.UPDATE_DELIVERY_MESSAGE, data);
export const updateDeliveryNote = createAsyncAction(
  ActionTypes.UPDATE_DELIVERY_MESSAGE_REQUEST,
  ActionTypes.UPDATE_DELIVERY_MESSAGE_SUCCESS,
  ActionTypes.UPDATE_DELIVERY_MESSAGE_FAILURE,
)<void, {}, Error>();
export const updateDeliveryNotesSubmit = (data: any) => action(ActionTypes.UPDATE_DELIVERY_MESSAGES, data);
export const updateDeliveryNotes = createAsyncAction(
  ActionTypes.UPDATE_DELIVERY_MESSAGES_REQUEST,
  ActionTypes.UPDATE_DELIVERY_MESSAGES_SUCCESS,
  ActionTypes.UPDATE_DELIVERY_MESSAGES_FAILURE,
)<void, {}, Error>();
export const updateStoreSubmit = (data: any) => action(ActionTypes.UPDATE_STORE, data);
export const updateStore = createAsyncAction(
  ActionTypes.UPDATE_STORE_REQUEST,
  ActionTypes.UPDATE_STORE_SUCCESS,
  ActionTypes.UPDATE_STORE_FAILURE,
)<void, {}, Error>();
