import { call, take, put, fork, all } from 'redux-saga/effects';
import { message } from 'antd';
import {
  deliveryNotes as deliveryNotesActions,
  deleteDeliveryNote as deleteDeliveryNoteActions,
  updateDeliveryNote as updateDeliveryNoteActions,
  updateDeliveryNotes as updateDeliveryNotesActions,
  updateStore as updateStoreActions,
} from './actions';
import ActionTypes from './constants';
import { setSelectedStore } from 'containers/MainLayout/actions';
import translations from 'translations';
import messages from './messages';
import {
  getDeliveryNotes,
  updateDeliveryNote,
  removeDeliveryNote,
  updateStore,
  createDeliveryNote,
} from 'utils/apollo';

function* initDataFlow() {
  yield put(deliveryNotesActions.request());
  const response = yield call(getDeliveryNotes);
  if (!response.errors) {
    yield put(deliveryNotesActions.success(response));
  } else {
    yield put(deliveryNotesActions.failure(response.errors));
  }
}

function* deleteDeliveryNoteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.DELETE_DELIVERY_MESSAGE);

    yield put(deleteDeliveryNoteActions.request());
    const response = yield call(removeDeliveryNote, payload);
    if (!response.errors) {
      yield put(deleteDeliveryNoteActions.success({ ...response, ...payload }));
    } else {
      yield put(deleteDeliveryNoteActions.failure(response.errors));
    }
  }
}

function* updateDeliveryNoteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.UPDATE_DELIVERY_MESSAGE);

    yield put(updateDeliveryNoteActions.request());
    const response = yield call(updateDeliveryNote, payload);
    if (!response.errors) {
      yield put(updateDeliveryNoteActions.success({ ...response, ...payload }));
    } else {
      yield put(updateDeliveryNoteActions.failure(response.errors));
    }
  }
}

function* updateDeliveryNotesFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.UPDATE_DELIVERY_MESSAGES);
    yield put(updateDeliveryNotesActions.request());
    const { createNotes, updateNotes } = payload;
    yield all([
      ...createNotes.map(note => call(createDeliveryNote, note)),
      ...updateNotes.map(note => call(updateDeliveryNote, note)),
    ]);
    message.success(translations(messages.saveDeliveryNoteSuccess));
    yield call(initDataFlow);
  }
}

function* updateStoreFlow() {
  while (true) {
    const {
      payload: { __typename, ...payload },
    } = yield take(ActionTypes.UPDATE_STORE);

    yield put(updateStoreActions.request());
    delete payload.loading;
    const response = yield call(updateStore, {
      input: payload,
    });
    if (!response.errors) {
      yield put(updateStoreActions.success({ ...response, ...payload }));
      yield put(setSelectedStore(payload));
    } else {
      yield put(updateStoreActions.failure(response.errors));
    }
  }
}

// Individual exports for testing
export default function* storeInfoPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(initDataFlow);
  yield fork(deleteDeliveryNoteFlow);
  yield fork(updateDeliveryNoteFlow);
  yield fork(updateDeliveryNotesFlow);
  yield fork(updateStoreFlow);
}
