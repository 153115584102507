import { Input as AInput, Modal as AModal, Col, Row } from 'antd';
import Form, { Item } from '../Form/Form';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Component } from 'react';

import { Avatar } from '../Company/Avatar';
import { Box } from '../Box/Box';
import Input from 'components/TrackingComponents/Input';
import TrackingButton from 'components/TrackingComponents/Button';
import messages from 'containers/StoreInfoPage/messages';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import formatFullAddress from 'utils/formatFullAddress';

const Button = styled(TrackingButton)<any>``;
const TextArea = styled(AInput.TextArea)<any>``;

const InfoContainer = styled.div`
  margin-bottom: 6pt;
`;

const DeleteBtnContainer = styled.div`
  ${Button} {
    border-color: transparent;
    :hover,
    :active,
    :focus {
      border-color: transparent;
    }
  }
`;

const Side = styled(Box)``;

const Body = styled.div``;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4pt;
`;

const Label = styled.span`
  color: rgba(0, 0, 0, 0.54);
  min-width: 100pt;
`;

const Value = styled.span`
  word-break: break-word;
`;

const UpdateMessageForm = styled(Form)``;
const FormRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Heading = styled.h4`
  margin-bottom: 6pt;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

class Store extends Component<{
  intl: any;
  store: any;
  deliveryNotes: any[];
  onDeleteDeliveryMessage: (item: any) => void;
  onUpdateDeliveryMessage: (items: any) => void;
  onUpdateStore: (items: any) => void;
  onUpdateDeliveryMessages: (data: any) => void;
}> {
  public state = {
    notes: [...this.props.deliveryNotes],
    imageUrl: this.props.store.imageUrl || '',
    edited: false,
    formRef: React.createRef(),
  };

  public componentWillReceiveProps(nextProps: any) {
    this.setState({
      notes: [...nextProps.deliveryNotes],
    });
  }

  public setOpenEditModal = (value: any) => {
    this.setState({
      ...this.state,
      isOpenEditModal: value,
    });
  };

  public onSetImageUrl = (url: string) => this.setState({ ...this.state, imageUrl: url });

  public setNotes = (value: any) => {
    this.setState({
      ...this.state,
      notes: value,
      edited: true,
    });
  };
  public onDeleteDeliveryMessageItem = (item: any, index: number) => () => {
    const cancelOrderMessage = this.props.intl.formatMessage(messages.deleteDeliveryNoteMessage);
    const yes = this.props.intl.formatMessage(utilsMessages.yes);
    const no = this.props.intl.formatMessage(utilsMessages.no);
    AModal.confirm({
      autoFocusButton: null,
      okButtonProps: {
        danger: true,
      },
      title: cancelOrderMessage,
      okText: yes,
      cancelText: no,
      onOk: () => {
        const { notes } = this.state;
        if (item.id) {
          this.props.onDeleteDeliveryMessage(item);
        }
        notes.splice(index, 1);
        this.setNotes(notes);
      },
    });
  };
  public saveNotes = () => {
    const { deliveryNotes } = this.props;
    const { notes } = this.state;
    const createNotes: any[] = [];
    const updateNotes: any[] = [];
    notes.forEach((note, index) => {
      if (!note.id && note.text.trim()) {
        createNotes.push(note);
      }
      if (deliveryNotes[index] && deliveryNotes[index].text !== note.text && note.id) {
        updateNotes.push(note);
      }
    });
    this.props.onUpdateDeliveryMessages({
      createNotes: createNotes,
      updateNotes: updateNotes,
    });
    this.setState({
      ...this.state,
      edited: false,
    });
  };

  public onChangeDelivryNotes = (index) => (event: any) => {
    const { notes } = this.state;
    notes[index] = {
      ...notes[index],
      text: event.target.value,
    };
    this.setNotes(notes);
  };

  public onAddMoreNote = () => {
    const { notes } = this.state;
    this.setNotes([...notes, { text: '' }]);
  };

  public render() {
    const { store } = this.props;
    const { notes } = this.state;
    const alternativeImage = (name) => `https://ui-avatars.com/api/?name=${name}&size=512`;
    return (
      <Row gutter={8}>
        <Col lg={7} xs={24}>
          <Side>
            <Avatar src={store.imageUrl || alternativeImage(store.name)} disabled={true} />
            <InfoContainer>
              <Heading>
                <FormattedMessage {...messages.header} />
              </Heading>
              <Info>
                <Label>
                  <FormattedMessage {...messages.name} />
                </Label>
                <Value>{store.name}</Value>
              </Info>
              <Info>
                <Label>
                  <FormattedMessage {...messages.address} />
                </Label>
                <Value>{formatFullAddress(store?.location)}</Value>
              </Info>
              <Info>
                <Label>
                  <FormattedMessage {...messages.phone} />
                </Label>
                <Value>{store.telephone}</Value>
              </Info>
            </InfoContainer>
          </Side>
        </Col>
        <Col lg={17} xs={24}>
          <Body>
            <Box>
              <Heading>
                <FormattedMessage {...messages.deliveryMessages} />
              </Heading>
              <UpdateMessageForm>
                {notes.map((dn: any, index) => (
                  <Item key={dn.id || index}>
                    <FormRow>
                      <Input
                        InputComponent={TextArea}
                        trackingCategory="Store | Input"
                        trackingAction="Enter Delivery Message"
                        value={dn.text}
                        onChange={this.onChangeDelivryNotes(index)}
                      />
                      <DeleteBtnContainer>
                        <Button
                          trackingCategory="Store | Button"
                          trackingAction="Attempt to Delete Delivery Message"
                          type="danger"
                          icon={<DeleteOutlined />}
                          size="small"
                          style={{ color: 'rgba(0,0,0,0.54)' }}
                          ghost
                          onClick={this.onDeleteDeliveryMessageItem(dn, index)}
                        />
                      </DeleteBtnContainer>
                    </FormRow>
                  </Item>
                ))}
                <Actions>
                  <Button
                    trackingCategory="Store | Button"
                    trackingAction="Add More Delivery Message"
                    onClick={this.onAddMoreNote}
                    icon={<PlusOutlined />}
                    type="link"
                    size="small"
                  >
                    <FormattedMessage {...messages.addMore} />
                  </Button>
                </Actions>
                <Button
                  trackingCategory="Store | Button"
                  trackingAction="Save Delivery Message"
                  disabled={!this.state.edited}
                  type="primary"
                  onClick={this.saveNotes}
                >
                  <FormattedMessage {...messages.save} />
                </Button>
              </UpdateMessageForm>
            </Box>
          </Body>
        </Col>
      </Row>
    );
  }
}

export default injectIntl(Store);
