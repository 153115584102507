/**
 *
 * StoreInfoPage
 *
 */

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import translations from 'translations';

import Store from 'components/Store/Store';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import {
  deleteDeliveryNoteSubmit,
  updateDeliveryNotesSubmit,
  updateDeliveryNoteSubmit,
  updateStoreSubmit,
} from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import selectStoreInfoPage from './selectors';

import { selectStore } from 'containers/MainLayout/selectors';
import { RootState } from './types';

interface OwnProps {
  storeinfopage: any;
  store: any;
}

interface StateProps {}

interface DispatchProps {
  onDeleteDeliveryMessage: (data: any) => void;
  onUpdateDeliveryMessage: (data: any) => void;
  onUpdateStore: (data: any) => void;
  onUpdateDeliveryMessages: (data: any) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class StoreInfoPage extends React.PureComponent<Props> {
  public onUpdateStore = (store: any) => {
    this.props.onUpdateStore(store);
  };

  public render() {
    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        <Store
          store={this.props.store}
          deliveryNotes={this.props.storeinfopage.deliveryNotes}
          onDeleteDeliveryMessage={this.props.onDeleteDeliveryMessage}
          onUpdateDeliveryMessage={this.props.onUpdateDeliveryMessage}
          onUpdateStore={this.onUpdateStore}
          onUpdateDeliveryMessages={this.props.onUpdateDeliveryMessages}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  storeinfopage: selectStoreInfoPage(),
  store: selectStore(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onDeleteDeliveryMessage: (data: any) => dispatch(deleteDeliveryNoteSubmit(data)),
    onUpdateDeliveryMessage: (data: any) => dispatch(updateDeliveryNoteSubmit(data)),
    onUpdateDeliveryMessages: (data: any) => dispatch(updateDeliveryNotesSubmit(data)),
    onUpdateStore: (data: any) => dispatch(updateStoreSubmit(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'storeInfoPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'storeInfoPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(StoreInfoPage);
