/*
 *
 * StoreInfoPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/StoreInfoPage/DEFAULT_ACTION',
  FETCH_DELIVERY_NOTE_REQUEST = 'app/StoreInfoPage/FETCH_DELIVERY_NOTE_REQUEST',
  FETCH_DELIVERY_NOTE_SUCCESS = 'app/StoreInfoPage/FETCH_DELIVERY_NOTE_SUCCESS',
  FETCH_DELIVERY_NOTE_FAILURE = 'app/StoreInfoPage/FETCH_DELIVERY_NOTE_FAILURE',
  DELETE_DELIVERY_MESSAGE = 'app/StoreInfoPage/DELETE_DELIVERY_MESSAGE',
  DELETE_DELIVERY_MESSAGE_REQUEST = 'app/StoreInfoPage/DELETE_DELIVERY_MESSAGE_REQUEST',
  DELETE_DELIVERY_MESSAGE_SUCCESS = 'app/StoreInfoPage/DELETE_DELIVERY_MESSAGE_SUCCESS',
  DELETE_DELIVERY_MESSAGE_FAILURE = 'app/StoreInfoPage/DELETE_DELIVERY_MESSAGE_FAILURE',
  UPDATE_DELIVERY_MESSAGE = 'app/StoreInfoPage/UPDATE_DELIVERY_MESSAGE',
  UPDATE_DELIVERY_MESSAGE_REQUEST = 'app/StoreInfoPage/UPDATE_DELIVERY_MESSAGE_REQUEST',
  UPDATE_DELIVERY_MESSAGE_SUCCESS = 'app/StoreInfoPage/UPDATE_DELIVERY_MESSAGE_SUCCESS',
  UPDATE_DELIVERY_MESSAGE_FAILURE = 'app/StoreInfoPage/UPDATE_DELIVERY_MESSAGE_FAILURE',
  UPDATE_DELIVERY_MESSAGES = 'app/StoreInfoPage/UPDATE_DELIVERY_MESSAGES',
  UPDATE_DELIVERY_MESSAGES_REQUEST = 'app/StoreInfoPage/UPDATE_DELIVERY_MESSAGES_REQUEST',
  UPDATE_DELIVERY_MESSAGES_SUCCESS = 'app/StoreInfoPage/UPDATE_DELIVERY_MESSAGES_SUCCESS',
  UPDATE_DELIVERY_MESSAGES_FAILURE = 'app/StoreInfoPage/UPDATE_DELIVERY_MESSAGE_FAILURE',
  UPDATE_STORE = 'app/StoreInfoPage/UPDATE_STORE',
  UPDATE_STORE_REQUEST = 'app/StoreInfoPage/UPDATE_STORE_REQUEST',
  UPDATE_STORE_SUCCESS = 'app/StoreInfoPage/UPDATE_STORE_SUCCESS',
  UPDATE_STORE_FAILURE = 'app/StoreInfoPage/UPDATE_STORE_FAILURE',
}

export default ActionTypes;
